import {
  type RouteDefinition,
  createAsync,
  query,
  useSearchParams,
} from "@solidjs/router";
import { For, Show } from "solid-js";

import { gql } from "~/__gql-generated__";
import Pages from "~/components/Pages";
import postStyles from "~/components/Post.module.scss";
import rootStyles from "~/components/Root.module.scss";
import Title from "~/components/Title";
import NotFound from "~/routes/[...404]";
import styles from "~/routes/enquetes.module.scss";
import { coalescePage } from "~/utils/coalescePage";
import { generateWidthThumbs } from "~/utils/generateThumbs";
import { client } from "~/utils/graphql";

const PER_PAGE = 20;

const POLLS = gql(`
  query Polls($limit: Int!, $offset: Int!) {
    pollsAggregate {
      count
    }
    polls(
      options: { sort: { createdAt: DESC }, limit: $limit, offset: $offset }
    ) {
      id
      title
      createdAt
      options(options: { sort: { weight: ASC } }) {
        id
        title
        votes
      }
      optionsAggregate {
        node {
          votes {
            sum
          }
        }
      }
    }
  }
`);

const getPolls = query(async (page: number) => {
  "use server";

  const { data } = await client.query({
    query: POLLS,
    variables: {
      limit: PER_PAGE,
      offset: page * PER_PAGE,
    },
  });

  return {
    polls: data.polls,
    count: data.pollsAggregate.count,
    heroImageSet: generateWidthThumbs(
      `${import.meta.env.VITE_SITE_HOST}/img/enquetes.png`,
      16 / 9,
    ),
  };
}, "polls");

export const route = {
  preload: ({ location }) => {
    const parameters = new URLSearchParams(location.search.slice(1));
    return getPolls(coalescePage(parameters.get("p")));
  },
} satisfies RouteDefinition;

export default function PollList() {
  const [parameters] = useSearchParams();
  const page = () => coalescePage(parameters.p?.toString());

  const data = createAsync(() => getPolls(page()), {
    initialValue: { polls: [], count: -1, heroImageSet: "" },
  });

  return (
    <Show when={data().count} fallback={<NotFound />}>
      <main>
        <Title>Enquetes</Title>
        <article>
          <div class={postStyles.hero}>
            <img
              src="/img/enquetes.png"
              class={postStyles["hero-image"]}
              srcSet={data().heroImageSet}
              alt=""
            />
            <h1>Enquetes</h1>
            <p>
              Estas são as enquetes passadas do <i lang="en">site</i>.
            </p>
          </div>
          <div class={postStyles.body}>
            <For each={data().polls}>
              {(poll) => {
                const totalVotes = poll.optionsAggregate?.node?.votes.sum ?? 0;

                return (
                  <>
                    <h2>{poll.title}</h2>
                    <ul
                      classList={{
                        [rootStyles["clean-list"]]: true,
                        [styles["poll-options"]]: true,
                      }}
                    >
                      <For each={poll.options}>
                        {(option) => {
                          const percent =
                            totalVotes > 0 ? option.votes / totalVotes : 0;
                          return (
                            <li
                              style={{
                                "--percent": `${(percent * 100).toString()}%`,
                              }}
                            >
                              {option.title}{" "}
                              <small>
                                (
                                {percent.toLocaleString(
                                  import.meta.env.VITE_LOCALE,
                                  {
                                    style: "percent",
                                    minimumFractionDigits: 2,
                                  },
                                )}
                                )
                              </small>
                            </li>
                          );
                        }}
                      </For>
                    </ul>
                    <p>
                      {totalVotes.toLocaleString(import.meta.env.VITE_LOCALE)}{" "}
                      {totalVotes >= 2 ? "votos" : "voto"} · criada a{" "}
                      {new Date(poll.createdAt).toLocaleString(
                        import.meta.env.VITE_LOCALE,
                        {
                          dateStyle: "long",
                          timeStyle: "short",
                          timeZone: import.meta.env.VITE_TZ,
                        },
                      )}
                    </p>
                    <hr />
                  </>
                );
              }}
            </For>
          </div>
        </article>
        <Pages page={page()} total={Math.ceil(data().count / PER_PAGE)} />
      </main>
    </Show>
  );
}

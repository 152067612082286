import {
  type RouteDefinition,
  createAsync,
  query,
  useSearchParams,
} from "@solidjs/router";
import { For, Show, createSignal } from "solid-js";
import { isServer } from "solid-js/web";

import { innerGetFanGallery } from "~/components/FanGallery";
import MaybeA from "~/components/MaybeA";
import Pages from "~/components/Pages";
import postStyles from "~/components/Post.module.scss";
import rootStyles from "~/components/Root.module.scss";
import Title from "~/components/Title";
import NotFound from "~/routes/[...404]";
import styles from "~/routes/desenho-ouvinte.module.scss";
import { clientLazy } from "~/utils/clientLazy";
import { coalescePage } from "~/utils/coalescePage";
import { generateWidthThumbs } from "~/utils/generateThumbs";

const FanGalleryFullscreen = clientLazy(
  () => import("~/components/FanGalleryFullscreen"),
);

const PER_PAGE = 20;
const getFanGallery = query(innerGetFanGallery, "fanGalleryListing");

export const route = {
  preload: ({ location }) => {
    const parameters = new URLSearchParams(location.search.slice(1));
    return getFanGallery(PER_PAGE, coalescePage(parameters.get("p")));
  },
} satisfies RouteDefinition;

export default function FanGallery() {
  const [parameters] = useSearchParams();
  const page = () => coalescePage(parameters.p?.toString());
  const data = createAsync(() => getFanGallery(PER_PAGE, page()), {
    initialValue: { items: [], count: -1, heroImageSet: "" },
  });

  const [fullscreen, setFullscreen] = createSignal(false);
  const [activeIndex, setActiveIndex] = createSignal(-1);

  return (
    <Show when={data().count > 0} fallback={<NotFound />}>
      <main>
        <Title>Galeria do Ouvinte</Title>
        <article>
          <div class={postStyles.hero}>
            <img
              src="/img/desenho-ouvinte.png"
              class={postStyles["hero-image"]}
              srcSet={data().heroImageSet}
              alt=""
            />
            <h1>Galeria do Ouvinte</h1>
            <p>Confira os desenhos que os ouvintes mandaram para nós.</p>
          </div>
          <div
            classList={{
              [postStyles.body]: true,
              [rootStyles["styled-links"]]: true,
              [styles.grid]: true,
            }}
          >
            <For each={data().items}>
              {(item, index) => (
                <div class={styles.item}>
                  <div class={styles.image}>
                    <img src={item.image} alt={item.title} />
                  </div>
                  <h2>
                    <button
                      type="button"
                      class={rootStyles.link}
                      onClick={() => {
                        setActiveIndex(index());
                        setFullscreen(true);
                      }}
                    >
                      <cite>{item.title}</cite>
                    </button>
                  </h2>
                  <p class={styles.meta}>
                    por{" "}
                    <MaybeA href={item.authorSocial} rel="external">
                      {item.author}
                    </MaybeA>{" "}
                    a {new Date(item.createdAt).toLocaleString("pt-BR")}
                  </p>
                  <p>{item.description}</p>
                </div>
              )}
            </For>
          </div>
        </article>
        <Show when={!isServer}>
          <FanGalleryFullscreen
            open={fullscreen()}
            active={activeIndex()}
            data={data().items}
            onClose={() => {
              setFullscreen(false);
            }}
            onChangeActive={setActiveIndex}
          />
        </Show>
        <Pages page={page()} total={Math.ceil(data().count / PER_PAGE)} />
      </main>
    </Show>
  );
}

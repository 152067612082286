import { Link, Meta, MetaProvider } from "@solidjs/meta";
import { type RouteSectionProps, useLocation } from "@solidjs/router";
import { clientOnly } from "@solidjs/start";
import { Suspense } from "solid-js";
import { SolidNProgress } from "solid-progressbar";

import "~/code-highlight.scss";
import "~/pollen.css";
import BackToTop from "~/components/BackToTop";
import Footer from "~/components/Footer";
import Header from "~/components/Header";
import PlayerContext from "~/components/PlayerContext";
import "~/components/Root.module.scss";
import Description from "./Description";

const Player = clientOnly(() => import("~/components/player"));
const SongLyricsModal = clientOnly(
  () => import("~/components/player/SongLyricsModal"),
);
const SongRequestModal = clientOnly(
  () => import("~/components/SongRequestModal"),
);

export default function Root(props: RouteSectionProps) {
  return (
    <MetaProvider>
      <DefaultHead />
      <PlayerContext>
        <Suspense>
          <SolidNProgress
            color="light-dark(var(--color-sky-400), var(--color-sky-600))"
            stopDelayMs={0}
            options={{ showSpinner: false }}
          />
          <Header />
          {props.children}
          <Footer />
          <BackToTop />
          <Player />
          <SongLyricsModal />
          <SongRequestModal />
        </Suspense>
      </PlayerContext>
    </MetaProvider>
  );
}

function DefaultHead() {
  const location = useLocation();
  const canonicalUrl = () =>
    import.meta.env.VITE_SITE_HOST.replaceAll(/\/+$/g, "/") +
    `${location.pathname}/`.replaceAll(/\/{2,}/g, "/") +
    `?${location.search}`.replaceAll(/^\?{2,}/g, "");

  return (
    <>
      <Description>
        Web-rádio dedicada à música e à cultura japonesas. Do seu jeito, do seu
        gosto!
      </Description>
      <Link rel="canonical" href={canonicalUrl()} />
      <Meta property="og:url" content={canonicalUrl()} />
      <Meta property="og:type" content="website" />
      <Meta property="og:site_name" content="Rádio J-Hero" />
      <Meta property="og:locale" content="pt-BR" />
      <Meta property="og:image" content="/assets/logo.png" />
      <Meta name="twitter:card" content="summary" />
      <Meta name="twitter:site" content="@RadioJHero" />
    </>
  );
}

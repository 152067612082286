import { type RouteDefinition, createAsync, query } from "@solidjs/router";
import type { Root } from "hast";
import { For, Show } from "solid-js";

import { gql } from "~/__gql-generated__";
import { UserRole } from "~/__gql-generated__/graphql";
import postStyles from "~/components/Post.module.scss";
import rootStyles from "~/components/Root.module.scss";
import SmartA from "~/components/SmartA";
import Title from "~/components/Title";
import styles from "~/routes/equipe.module.scss";
import { collectHeadingLinks } from "~/utils/collectHeadingLinks";
import { fragmentToJsx } from "~/utils/fragmentToJsx";
import { generateWidthThumbs } from "~/utils/generateThumbs";
import { client } from "~/utils/graphql";
import renderRawFragment from "~/utils/renderRawFragment";

const roleMap: Record<string, UserRole[]> = {
  [UserRole.Admin]: [
    UserRole.Super,
    UserRole.Admin,
    UserRole.AudioAdmin,
    UserRole.AuthorAdmin,
    UserRole.DjAdmin,
    UserRole.PrAdmin,
  ],
  [UserRole.Dj]: [UserRole.DjAdmin, UserRole.Dj],
  [UserRole.Author]: [UserRole.AuthorAdmin, UserRole.Author],
  [UserRole.Audio]: [UserRole.AudioAdmin, UserRole.Audio],
  [UserRole.Pr]: [UserRole.PrAdmin, UserRole.Pr],
};

const roleNames: Record<string, string> = {
  [UserRole.Admin]: "Administradores e assistentes",
  [UserRole.Dj]: "DJs",
  [UserRole.Author]: "Redatores",
  [UserRole.Audio]: "Áudio e plástica",
  [UserRole.Pr]:
    'Relações públicas e <abbr title="Criadores de Conteúdo">CDCs</abbr>',
};

const genderMap: Record<string, string> = {
  MALE: "\u2642",
  FEMALE: "\u2640",
  HIDDEN: "",
};

const USERS = gql(`
  query Users {
    users(
      where: { deletedAt: null, profile_NOT: null }
      options: { sort: { displayName: ASC } }
    ) {
      displayName
      roles
      profile {
        slug
        gender
        picture {
          url
        }
      }
    }
  }
`);

const getUsers = query(async () => {
  "use server";

  const { data } = await client.query({ query: USERS });

  const result: Record<
    string,
    {
      heading: Root;
      users: ((typeof data.users)[number] & { pictureSet: string })[];
    }
  > = {};

  for (const [role, mappedRoles] of Object.entries(roleMap)) {
    result[role] = {
      heading: await renderRawFragment(`<h2>${roleNames[role]}</h2>`),
      users: data.users
        .filter((user) => user.roles.some((role) => mappedRoles.includes(role)))
        .map((user) => ({
          ...user,
          pictureSet: generateWidthThumbs(user.profile?.picture.url ?? "", 1),
        })),
    };
  }

  return {
    result,
    heroImageSet: generateWidthThumbs(
      `${import.meta.env.VITE_SITE_HOST}/img/equipe.png`,
      16 / 9,
    ),
  };
}, "users");

export const route = {
  preload: () => getUsers(),
} satisfies RouteDefinition;

export default function Users() {
  const data = createAsync(() => getUsers());
  const entries = () => Object.values(data()?.result ?? {});

  return (
    <main>
      <Title>Equipe</Title>
      <article class={rootStyles["styled-links"]}>
        <div class={postStyles.hero}>
          <img
            src="/img/equipe.png"
            class={postStyles["hero-image"]}
            srcSet={data()?.heroImageSet}
            alt=""
          />
          <h1>Equipe</h1>
          <p>
            Conheça cada um dos membros que fazem a <b>Rádio J-Hero</b> ser o
            que é.
          </p>
        </div>
        <div class={postStyles.body}>
          {collectHeadingLinks(entries().map((entry) => entry.heading))}
          <For each={entries()}>
            {(entry) => (
              <Show when={entry.users.length > 0}>
                {fragmentToJsx(entry.heading.children[0])}
                <ul class={styles.list}>
                  <For each={entry.users}>
                    {(user) => (
                      <li>
                        <SmartA
                          class={rootStyles.pseudo}
                          href={`/${user.profile?.slug ?? ""}`}
                        >
                          <div class={styles.image}>
                            <img
                              src={user.profile?.picture.url}
                              srcSet={user.pictureSet}
                              sizes="auto"
                              loading="lazy"
                              alt=""
                            />
                          </div>
                          <span class={styles.name}>
                            <span>
                              {user.displayName}
                              <Show when={user.profile?.gender !== "HIDDEN"}>
                                <span
                                  class={
                                    styles[
                                      `gender-${user.profile?.gender.toLowerCase() ?? ""}`
                                    ]
                                  }
                                >
                                  {genderMap[user.profile?.gender ?? ""]}
                                </span>
                              </Show>
                            </span>
                          </span>
                        </SmartA>
                      </li>
                    )}
                  </For>
                </ul>
              </Show>
            )}
          </For>
        </div>
      </article>
    </main>
  );
}
